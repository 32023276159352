import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableContainer, Card, CardContent, Pagination, Grid } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import jwt_decode from 'jwt-decode';
import PostSkeleton from '../components/common/PostSkeleton';
import PostData from '../components/common/PostdData';
import { styled } from '@mui/system';
import config from '../config';
import { Box, Typography, IconButton } from '@mui/material';
import InboxIcon from '@mui/icons-material/Inbox';
import { useTranslation } from 'react-i18next';
const API_URL_BASE = config.API_DOMAIN;

// Interfaces
export interface SubscriptionOption {
  EndDate: string;
  Option: {
    id: number;
    subscription_id: number;
    duration_in_weeks: number;
    cost: number;
  };
  PurchaseDate: string;
  StartDate: string;
}

export interface Subscription {
  Description: string;
  ID: number;
  Name: string;
  Options: SubscriptionOption[];
}

export interface PostData {
  id: number;
  title: string;
  description: string;
  images: string[];
  price: number;
  currency: string;
  address: string;
  created_at: string;
  status: string;
  active: boolean;
  subscriptions: Subscription[];
}

interface DecodedToken {
  user_id: number;
  username: string;
  email: string;
  location: string;
  phoneNumber: string;
}
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976D2',
    },
    secondary: {
      main: '#FFC107',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});

const StyledCard = styled(Card)(({ theme }) => ({
  overflow: 'hidden',
  border: '1px solid #e0e0e0',
  borderRadius: '8px',
  '@media (min-width: 768px)': {
    margin: theme.spacing(4),
  },
}));

const StyledPaginationContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '16px',
  marginBottom: '45px',  // Increased bottom margin
});



// Myposts Component
function Myposts() {
  const [posts, setPosts] = useState<PostData[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const { t } = useTranslation();
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedData = jwt_decode(token) as DecodedToken;
      fetch(`${config.API_DOMAIN}/${decodedData.user_id}/properties`)
        .then((response) => response.json())
        .then(async (data) => {
          
          // Check if data is empty
          if (!data || data.length === 0) {
            setLoading(false);
            console.log("No posts found.");
            return; // Exit from this then block
          }

          const updatedPosts = await Promise.all(
            data.map(async (post: PostData) => {
              const subscriptionResponse = await fetch(`${config.API_DOMAIN}/users/${decodedData.user_id}/properties/${post.id}/subscriptions`);
              const subscriptionData = await subscriptionResponse.json();
              post.subscriptions = subscriptionData;
              return post;
            })
          );

          setPosts(updatedPosts);
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setLoading(false); // Ensure loading is set to false in case of any error
        });
    } else {
      setLoading(false); // Handle scenario where token is null or invalid
    }
}, []);


return (
  <ThemeProvider theme={theme}>
    <StyledCard>
      <CardContent>
        <Typography variant="h5" gutterBottom>
        {t('myadvertisement')}
        </Typography>
        <TableContainer component={Grid} container>
          <Grid item xs={12}>
            <Table aria-label="customized table">
              <TableBody>
                {loading ? (
                  Array.from(new Array(ITEMS_PER_PAGE)).map((_, index) => (
                    <PostSkeleton key={index} />
                  ))
                ) : posts.length === 0 ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    height="200px"  // Or any suitable height
                  >
                    <IconButton color="primary">
                      <InboxIcon fontSize="large" />
                    </IconButton>
                    <Typography variant="body1" color="textSecondary">
                    {t('No more properties to show')}
                    </Typography>
                  </Box>
                ) : (
                  posts.slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE).map((post) => (
                    <PostData post={post} key={post.id} />
                  ))
                )}
              </TableBody>
            </Table>
          </Grid>
        </TableContainer>
        {posts.length > 0 && (
          <StyledPaginationContainer>
            <Pagination
              count={Math.ceil(posts.length / ITEMS_PER_PAGE)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={(event, value) => setPage(value)}
            />
          </StyledPaginationContainer>
        )}
      </CardContent>
    </StyledCard>
  </ThemeProvider>
);

}

export default Myposts;