import React from 'react';

function Hero() {
  return (
    <div className="container my-2 mx-auto px-4 max-w-screen-sm">
      <div className="rounded-xl overflow-hidden shadow-lg">
        <img 
          src={`${process.env.PUBLIC_URL}/im2.png`} 
          alt="Hero Image" 
          className="w-full object-cover h-32"  
        />
      </div>
    </div>
  );
}

export default Hero;
