import React, { useState } from 'react';
import { Box, Modal, IconButton, CardMedia, Card, useTheme, useMediaQuery, styled } from '@mui/material';
import { Close } from '@mui/icons-material';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

interface GalleryProps {
    images: string[];
}

const GalleryStyled = styled('div')(({ theme }) => ({
  '& .image-gallery': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  '& .image-gallery-bullets': {
    position: 'absolute',
    bottom: theme.spacing(2),
    left: '50%',
    transform: 'translateX(-50%)'
  },
}));

const Gallery: React.FC<GalleryProps> = ({ images }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [open, setOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(0);

    const handleOpen = (index: number) => {
        setSelectedImageIndex(index);
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const galleryOptions = {
        items: images.map(image => ({ original: image, thumbnail: image })),
        showThumbnails: false,
        showPlayButton: false,
        showBullets: !isMobile,
        startIndex: selectedImageIndex,
    };

    return (
        <GalleryStyled>
            <Card elevation={5} sx={{ 
                width: isMobile ? '90vw' : '1000px', 
                height: isMobile ? '300px' : '400px', 
                margin: 'auto', 
                mt: 5, 
                borderRadius: 2 
            }}>
                {isMobile ? (
                    <ImageGallery {...galleryOptions} />
                ) : (
                    <Box
                        sx={{
                            position: 'relative',
                            display: 'grid',
                            gridTemplateColumns: '2fr 1fr',
                            gap: '8px',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleOpen(0)}
                    >
                        <CardMedia
                            component="img"
                            image={images[0]}
                            alt="primary-image"
                            sx={{
                                objectFit: 'cover',
                                borderRadius: '8px'
                            }}
                        />

                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px',
                            }}
                        >
                            {images.slice(1, 3).map((img, index) => (
                                <CardMedia
                                    component="img"
                                    image={img}
                                    alt={`secondary-${index}`}
                                    sx={{
                                        objectFit: 'cover', 
                                        height: 'calc(50% - 4px)', 
                                        borderRadius: '8px'
                                    }}
                                    onClick={() => handleOpen(index + 1)}
                                />
                            ))}
                        </Box>
                    </Box>
                )}

                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="gallery-modal"
                    aria-describedby="view-all-images"
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '90%',
                            height: '80vh',
                            bgcolor: 'background.paper',
                            boxShadow: 24,
                            p: 4,
                            borderRadius: '8px',
                            overflowY: 'auto',
                        }}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{ position: 'absolute', right: 8, top: 8 }}
                        >
                            <Close />
                        </IconButton>
                        <ImageGallery {...galleryOptions} />
                    </Box>
                </Modal>
            </Card>
        </GalleryStyled>
    );
}

export default Gallery;
