import React, { useState, useEffect } from 'react';
import { Popover, Button, Space, Select, Dropdown, Menu } from 'antd';
import { HomeOutlined, MoneyCollectOutlined } from '@ant-design/icons';
import LocationInput from './Location';

interface PropertyFilterProps {
  filterType: string;
  location: string;
  onFilterTypeChange: (type: string) => void;
  onLocationChange: (location: string) => void;
  sorting: string;
  onSortingChange: (sorting: string) => void;
}

const PropertyFilter: React.FC<PropertyFilterProps> = ({
  filterType,
  location,
  onFilterTypeChange,
  onLocationChange,
  sorting,
  onSortingChange,
}) => {
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 800);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 800);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleHomeMenuClick = (e: any) => {
    onFilterTypeChange(e.key);
    // Close the price range popover when a different filter type is selected.
    setPopoverVisible(false);
  };

  const homeMenu = (
    <Menu onClick={handleHomeMenuClick}>
     
      <Menu.Item key="House">House</Menu.Item>
      <Menu.Item key="Apartment">apartment</Menu.Item>
      {/* Remove the "Price" option */}
    </Menu>
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setPopoverVisible(true);
  };

  const handleClose = () => {
    setPopoverVisible(false);
  };

  return (
    <div className="flex overflow-x-scroll">
      <Dropdown overlay={homeMenu} placement="bottomLeft">
        <Button type="default" icon={<HomeOutlined />}>
          {filterType.charAt(0).toUpperCase() + filterType.slice(1)}
        </Button>
      </Dropdown>
      {filterType === 'price' ? ( // Show price input fields only when filter type is 'price'.
        <Popover
          content={
            <Space direction="vertical">
              <Button onClick={handleClose}>Close</Button>
            </Space>
          }
          trigger="click"
          visible={popoverVisible}
          onVisibleChange={setPopoverVisible}
        >
          <Button
            type="default"
            onClick={handleClick}
            icon={<MoneyCollectOutlined />}
          >
            Set Price Range
          </Button>
        </Popover>
      ) : null}
      <LocationInput location={location} onLocationChange={onLocationChange} />
      <Select
        style={{ minWidth: 120 }}
        value={sorting}
        onChange={onSortingChange}
      >
        <Select.Option value="newest">Any</Select.Option>
        <Select.Option value="lowToHigh">Low to High</Select.Option>
        <Select.Option value="highToLow">High to Low</Select.Option>
      </Select>
    </div>
  );
};

export default PropertyFilter;
