import React from 'react';
import { Paper, Typography, Button, Avatar, Chip, Skeleton } from '@mui/material';
import { FaEye, FaAd } from 'react-icons/fa';
import { styled } from '@mui/material/styles';

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  borderRadius: '12px',
  transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 6px 20px rgba(0,0,0,0.15)',
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 60,
  height: 60,
  borderRadius: '50%',
  border: '2px solid #fff',
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(1, 2),
  borderRadius: '8px',
  fontWeight: 'bold',
}));

const StyledSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.9rem',
  color: '#777',
}));

function PostDataSkeleton() {
  return (
    <StyledPaper elevation={3}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        <Skeleton variant="circular" width={60} height={60} />
        <div style={{ marginLeft: 12 }}>
          <Skeleton width="80%" height={24} />
          <Skeleton width="60%" height={20} />
          <Skeleton width="80%" height={20} />
        </div>
      </div>
      <Skeleton variant="text" width="100%" height={40} />
      <Skeleton variant="text" width="100%" height={40} />
      <Skeleton variant="text" width="100%" height={40} />
      <Typography variant="subtitle2" style={{ marginTop: 12, fontWeight: 'bold' }}>
        Subscriptions
      </Typography>
      <Skeleton variant="rectangular" width="100%" height={40} />
      <div style={{ marginTop: 16, display: 'flex' }}>
        <Skeleton variant="rectangular" width={120} height={40} style={{ marginRight: 8 }} />
        <Skeleton variant="rectangular" width={120} height={40} />
      </div>
    </StyledPaper>
  );
}

export default PostDataSkeleton;
