import React, { useState, useEffect } from 'react';
import Header from '../components/header/header';
import Categories from '../components/categories/categories';
import ImageCardList from '../components/common/ImageCardList';
import { Routes as Switch, Route, useLocation } from 'react-router-dom'; 
import PostProperty from './post';
import PostPropertyDetails from './ProrpertyDetails';
import Profile from './Profile';
import Properties from './Properties';
import Myposts from './Myposts';
import 'react-toastify/dist/ReactToastify.css';
import Posting from './Posting';
import Footer from '../components/footer/footer';
import { ChakraProvider } from '@chakra-ui/react';
import ComingSoon from '../components/common/Comingsoon';
import Success from '../components/common/succuess';
import Hero from '../components/common/Hero';
import PromoSection from '../components/common/promo';
import Security from '../components/common/security';
import Showpromoted from '../components/common/Showpromoted';

export default function App() {
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {!location.pathname.startsWith("/details/")  && location.pathname !== "/post" && <Header />} 
      <Switch>
        <Route path="/" element={
          <>
            {isMobile && <Hero/>}
            {isMobile && <PromoSection/>}
            {isMobile && <Security/>}
            {isMobile && <Showpromoted/>}
           
            
            <Categories />
            <ImageCardList />
            <Footer/>
          </>
        } />
        <Route path="/post" element={<Posting />} />
        <Route path="/properties" element={<Properties />} />
        <Route path="/soon" element={<ComingSoon />} />
        <Route path="/details/:id" element={<PostPropertyDetails />} />
        <Route path="/:id/properties" element={<Myposts />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/success" element={<Success />} />
      </Switch>
    </>
  );
}
