import React, { useEffect, useState, useRef } from 'react';
import { Grid, Button, CircularProgress } from '@mui/material';
import ImageCard from './ImageCard';
import ImageCardSkeleton from './ImageCardSkeleton';
import config from '../../config';
import { useTranslation } from 'react-i18next';

interface Property {
  id: number;
  user_id: number;
  title: string;
  description: string;
  price: number;
  currency: string;
  location: string;
  type: string;
  status: string;
  area: number;
  bedrooms: number;
  bathrooms: number;
  garage: boolean;
  created_at: string;
  updated_at: string;
  address: string;
  images: string[];
  active: boolean;
  subscriptions: Subscription[];
}

interface Subscription {
  id: number;
  name: string;
  description: string;
  options: SubscriptionOption[];
}

interface SubscriptionOption {
  id: number;
  subscription_id: number;
  duration_in_weeks: number;
  cost: number;
}

const PropertyList = ({ initialPage = 1, itemsPerPage = 8 }) => {
  const [data, setData] = useState<Property[]>([]);
  const [loading, setLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [page, setPage] = useState(initialPage);
  const [hasMore, setHasMore] = useState(true);
  const { t } = useTranslation(); // Using the hook

  const fetchProperties = async (currentPage: number) => {
    setLoading(true);
    try {
      const response = await fetch(`${config.API_DOMAIN}/properties?page=${currentPage}&limit=${itemsPerPage}`);
      const fetchedData: Property[] = await response.json();
  
      if (fetchedData.length === 0 || fetchedData.length < itemsPerPage) {
        setHasMore(false);
      }
      setData((prevData) => [...prevData, ...fetchedData]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      setIsLoadingMore(false);
    }
  };
  
  useEffect(() => {
    fetchProperties(page);
  }, []);
  
  const handleLoadMore = () => {
    if (!loading && !isLoadingMore) {
      setIsLoadingMore(true);
      setPage((prevPage) => {
        const newPage = prevPage + 1;
        fetchProperties(newPage);
        return newPage;
      });
    }
  };
  const mobileStyles = {
    '@media (max-width: 768px)': {
      width: '80%',
      margin: '0 auto'
    }
  };
  
  return (
    <div className="p-4 md:p-0 relative">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 property-list">
        {loading
          ? Array.from({ length: itemsPerPage }).map((_, idx) => (
              <ImageCardSkeleton key={idx} />
            ))
          : data.map((property) => (
              <div key={property.id} style={{ height: '100%' }}>
                <ImageCard property={property} />
              </div>
            ))}
      </div>

      <div className="text-center mb-12 w-full">
        {hasMore && (
          <Button
          variant="contained"
          onClick={handleLoadMore}
          style={{
              background: '#FF5A5F',
              color: 'white',
              borderRadius: '8px',
              padding: '10px 30px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
              fontSize: '16px',
              fontWeight: 600,
              transition: 'all 0.3s',
          }}
          disabled={isLoadingMore}
          >
            {isLoadingMore ? <CircularProgress size={24} /> : t('loadMore')}
          </Button>
        )}
        {!hasMore && (
          <div className="text-gray-500">{t('noMoreProperties')}</div>
        )}
      </div>
    </div>
  );
};

export default PropertyList;
