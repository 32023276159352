import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next'; // Import the hook
const PromoSection: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState(3600); // 1 hour in seconds
  const [promoIndex, setPromoIndex] = useState(0);
  const promoRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const promos = [
    "Post for free ",
    "Get free ad",
    
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        if (prevTimeLeft <= 0) {
          clearInterval(interval);
          return 0;
        }
        return prevTimeLeft - 1;
      });
    }, 1000);

    const promoInterval = setInterval(() => {
      if (promoRef.current) {
        promoRef.current.style.opacity = '0';
        setTimeout(() => {
          setPromoIndex((prevIndex) => (prevIndex + 1) % promos.length);
          if (promoRef.current) {
            promoRef.current.style.opacity = '1';
          }
        }, 500);
      }
    }, 5000); // Change promo every 5 seconds

    return () => {
      clearInterval(interval);
      clearInterval(promoInterval);
    };
  }, [promos.length]);

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      secs.toString().padStart(2, '0')
    ];
  };

  return (
    <div 
      style={{ backgroundColor: '#fdf4e8' }}
      className="flex justify-between p-2 border rounded-md shadow-sm items-center w-90% flex-wrap font-sans mx-4 mt-3">
      
      <div className="w-1/2 text-center px-1">
          <div className="font-semibold text-xs mb-1">{t('freeRegistration')}</div>
          <div className="flex justify-center items-center space-x-0.5 text-xs">
            <span>{t('timeLeft')}:</span>
            {formatTime(timeLeft).map((timeSegment, index) => (
              <div key={index} className="bg-black text-white font-mono rounded-sm px-1 py-0.5">
                {timeSegment}
              </div>
            ))}
          </div>
      </div>
  
      <div className="border-l w-1/2 text-center px-1 py-2">
          <div ref={promoRef} className="text-xs text-gray-700 transition-opacity duration-500 font-light">
            {t(promos[promoIndex])}
          </div>
      </div>
  
    </div>
  );
  
}

export default PromoSection;
