import React from 'react';
import { Card, CardContent, Typography, IconButton } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';

function Success() {
  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-4/5 max-w-xs bg-white rounded-lg shadow-lg overflow-hidden border border-green-400">
      <div className="bg-green-500 p-4 text-white text-center rounded-t-lg">
        <Typography variant="h5">Congratulations!</Typography>
      </div>
      <CardContent className="p-4 space-y-3">
        <div className="flex items-center justify-center">
          <IconButton color="success">
            <DoneIcon />
          </IconButton>
        </div>
        <Typography variant="h6" className="text-center">
          Property Posted!
        </Typography>
        <Typography variant="body2" className="text-center text-gray-600">
          Awaiting verification. It'll be live within an hour.
        </Typography>
      </CardContent>
    </div>
  );
}

export default Success;
