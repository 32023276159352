import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button as MuiButton } from '@mui/material';
import { styled } from '@mui/system';

const AirbnbButton = styled(MuiButton)({
  backgroundColor: '#FF5A5F',
  color: 'white',
  padding: '10px 10px',
  borderRadius: '50px', // make the button round
  '&:hover': {
    backgroundColor: '#FF3D44',
  },
  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.25)', // add a shadow effect
  fontWeight: 'bold', // make the text bold
  letterSpacing: '1px', // add some letter spacing
  transition: 'all 0.3s ease-in-out', // add a transition effect
  textTransform: 'uppercase', // make the text uppercase
});

interface ButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = ({ children, onClick }) => {
  return (
    <div>
      <AirbnbButton variant='contained' onClick={onClick}>
        {children}
      </AirbnbButton>
    </div>
  );
}

export default Button;
