import React from 'react';
import { useTranslation } from 'react-i18next';
import HomeIcon from '@mui/icons-material/Home';
import CarIcon from '@mui/icons-material/DriveEta';
import TshirtIcon from '@mui/icons-material/Style';
import PhoneIcon from '@mui/icons-material/Phone';
import SofaIcon from '@mui/icons-material/Weekend';
import WorkIcon from '@mui/icons-material/Work';
import ServiceIcon from '@mui/icons-material/RoomService';
import PetIcon from '@mui/icons-material/Pets';
import MachineryIcon from '@mui/icons-material/Build';
import PartsIcon from '@mui/icons-material/Extension';

interface Props {
  onCategorySelect: (categoryName: string) => void;
}

const Category: React.FC<Props> = ({ onCategorySelect }) => {
  const { t } = useTranslation();

  const categories = [
    { label: 'categories.realEstate', value: 'properties', icon: <HomeIcon /> },
    { label: 'categories.vehicles', value: 'soon', icon: <CarIcon /> },
    { label: 'categories.electronics', value: 'soon', icon: <PhoneIcon /> },
    { label: 'categories.homeFurniture', value: 'soon', icon: <SofaIcon /> },
    { label: 'categories.jobPostings', value: 'soon', icon: <WorkIcon /> },
    { label: 'categories.spareParts', value: 'soon', icon: <PartsIcon /> },
  ];

  return (
    <div className="p-4 md:p-6 w-full md:w-96 shadow-md rounded-xl bg-white border border-gray-200">
      <h3 className="text-xl md:text-2xl font-bold mb-4 text-gray-800">{t('Choose Category')}</h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {categories.map((category) => (
          <div 
            key={category.label}
            className={`relative ${category.value !== 'properties' ? 'opacity-60' : ''}`}
          >
            <button 
              onClick={() => onCategorySelect(category.value)}
              className={`flex flex-col items-center p-4 rounded-lg transition-transform transform hover:scale-105 active:scale-95 hover:bg-gray-100 shadow-sm w-full h-full ${category.value === 'soon' ? 'pointer-events-none' : ''}`}
              aria-disabled={category.value === 'soon'}
            >
              {React.cloneElement(category.icon, { color: 'primary', fontSize: 'inherit' })}
              <span className="mt-2 text-gray-700 font-medium text-base md:text-lg">{t(category.label)}</span>
            </button>
            {category.value !== 'properties' && (
    <span className="absolute top-1 right-1 bg-red-500 text-white text-sm py-0.5 px-2 rounded-full">{t('Soon')}</span>
)}

          </div>
        ))}
      </div>
    </div>
  );
};

export default Category;
