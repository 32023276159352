import React from 'react';
import { Card, CardContent, Skeleton } from '@mui/material';
import { styled } from '@mui/system';

const StyledCard = styled(Card)({
    maxWidth: 1000,
    width: '95%',
    margin: '0 auto',
    borderRadius: 20,
    boxShadow: 'none',
    height: '100%',
});

const StyledCardContent = styled(CardContent)({});

const ImageCardSkeleton = () => {
    return (
        <StyledCard>
            <Skeleton variant="rectangular" width="100%" height={300} sx={{ borderRadius: '20px' }} />
            <StyledCardContent>
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="40%" />
            </StyledCardContent>
        </StyledCard>
    );
};

export default ImageCardSkeleton;
