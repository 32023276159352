import React from 'react';
import { Container, Typography, Divider } from '@mui/material';
import { Table, Row, Col } from 'antd'; // Import Ant Design components
import 'tailwindcss/tailwind.css';
import { IPropertyForm } from './PostForm';
import Subscriptions, { Subscription, SubscriptionOption } from './Subscribtions';
import ImageUploader from './ImageUploader';
import config from '../../config';
import { useTranslation } from 'react-i18next'; // Assuming you're using react-i18next

const API_DOMAIN = config.API_DOMAIN;

interface CheckoutProps {
  homeType: string | null;
  buyRent: string | null;
  propertyDetails: IPropertyForm | null;
  uploadedImages: File[];
  selectedSubscriptions: { sub: Subscription; option: SubscriptionOption; }[];
  paymentImages: File[];
  setPaymentImages: React.Dispatch<React.SetStateAction<File[]>>;
}

const Checkout: React.FC<CheckoutProps> = ({
  homeType,
  buyRent,
  propertyDetails,
  uploadedImages,
  selectedSubscriptions,
  paymentImages,
  setPaymentImages
}) => {
  const { t } = useTranslation(); // Initialize translation function
  const totalCost = selectedSubscriptions.reduce(
    (accumulator, current) => accumulator + current.option.cost,
    0
  );

  const columns = [
    {
      title: t('Subscription'),
      dataIndex: ['sub', 'name'],
      key: 'sub.name',
    },
    {
      title: t('Duration (Weeks)'),
      dataIndex: ['option', 'duration_in_weeks'],
      key: 'option.duration_in_weeks',
    },
    {
      title: t('Price'),
      dataIndex: ['option', 'cost'],
      key: 'option.cost',
      render: (cost: number | undefined) => (
        cost !== undefined ? `$${cost.toFixed(2)}` : 'N/A'
      ),
    },
  ];

  const dataSource = selectedSubscriptions.map((subscription, index) => ({
    key: index,
    ...subscription,
  }));

  const handleFileChange = (files: File[]) => {
    setPaymentImages(files);
  };

  return (
    <Container maxWidth="md" className="mt-10 md:mt-20">
      <div className="p-4 md:p-8 space-y-6 md:space-y-10 rounded-lg shadow-md">
        <Typography variant="h5" align="center" gutterBottom>
          {t('Checkout Summary')}
        </Typography>

        <Divider className="mb-4" />

        <Table columns={columns} dataSource={dataSource} pagination={false} className="table-fixed" />

        <Row justify="end" className="mt-4">
          <Col>
            <Typography variant="subtitle1" className="mr-2">
              {t('Total')}:
            </Typography>
          </Col>
          <Col>
            <Typography variant="h6" style={{ color: 'blue' }}>
              ${totalCost.toFixed(2)}
            </Typography>
          </Col>
        </Row>

        {totalCost > 0 && (
          <div className="mt-4">
            <Typography variant="h6" align="center" gutterBottom>
              {t('Payment Instructions')}
            </Typography>
            <Divider className="mb-4" />
            <Typography variant="body2" className="text-gray-700">
              {t('Please send the total amount to our EVC number')}:
              <strong className="ml-2 font-bold">123-456-789</strong>.
              <br />
              {t('Then, upload a screenshot of the transaction below')}.
            </Typography>

            <div className="mt-4">
              <ImageUploader onFileChange={handleFileChange} />
            </div>
          </div>
        )}
      </div>
    </Container>
  );
};

export default Checkout;
