import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material';

interface LanguageProps {
  handleLanguageChange: (event: SelectChangeEvent<string>) => void;
}

const Language: React.FC<LanguageProps> = ({ handleLanguageChange }) => {
  const { i18n } = useTranslation();
  const recognizedLanguages = ['en', 'so'];

  const currentLanguage = recognizedLanguages.includes(i18n.language) ? i18n.language : 'so';

  const internalHandleLanguageChange = (event: SelectChangeEvent<string>) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
    handleLanguageChange(event);
  };

  return (
    <FormControl 
      variant="outlined" 
      sx={{
        minWidth: '16px', 
        borderRadius: '2px', 
        padding: '0px',
        '& .MuiOutlinedInput-notchedOutline': { border: 'none' }
      }}>
      <InputLabel id="language-select-label" sx={{ display: 'none' }}>Language</InputLabel>
      <Select
        labelId="language-select-label"
        id="language-select"
        onChange={internalHandleLanguageChange}
        label="Language"
        value={currentLanguage}   // Set the current or default language
        sx={{ 
          borderRadius: '4px', 
          '& .MuiSelect-icon': { color: '#1890ff' }, 
          '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: 'white' },
          transition: '0.3s ease',
        }}
      >
        <MenuItem value="en">
          🇺🇸 En
        </MenuItem>
        <MenuItem value="so">
          🇸🇴 Som
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default Language;
