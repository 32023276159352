import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useNavigate } from 'react-router-dom';

interface PromotedProps {
  id: number;
  imageUrl: string;
  price: number;
  status: string;
  loading?: boolean;
}

const Promoted: React.FC<PromotedProps> = ({ id, imageUrl, price, status, loading = false }) => {
  const navigate = useNavigate();

  if (loading) {
    return (
      <Paper elevation={3} style={{ width: '100%', margin: '0 auto' }}>
        <Skeleton variant="rectangular" height={100} style={{ borderRadius: '10px 10px 0 0' }} />
        <Grid container direction="row" alignItems="center" style={{ padding: '8px' }}>
          <Skeleton variant="text" width={60} style={{ flexGrow: 1 }} />
          <Skeleton variant="text" width={40} />
        </Grid>
      </Paper>
    );
  }

  return (
    <div onClick={() => navigate(`/details/${id}`)}>
      <Paper elevation={3} style={{ width: '100%', margin: '0 auto' }}>
        <img 
          src={imageUrl} 
          alt={`Property ${id}`} 
          style={{
            width: '100%',
            height: '100px',
            objectFit: 'cover',
            borderRadius: '10px 10px 0 0',
            margin: 0,
            padding: 0
          }}
        />
        <Grid container direction="row" alignItems="center" style={{ padding: '8px' }}>
          <Typography 
            variant="body2" 
            component="span" 
            style={{ fontWeight: 'bold', color: '#FF5A5F', flexGrow: 1 }}
          >
            ${price}
          </Typography>
          <Typography 
            variant="caption" 
            component="span" 
            style={{ color: 'gray' }}
          >
            {status}
          </Typography>
        </Grid>
      </Paper>
    </div>
  );
}

export default Promoted;
