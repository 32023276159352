import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: 'so',  // Set default language to English
    fallbackLng: 'en', // use en if detected lng is not available
    detection: {
      order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json', // load translations from /public/locales/{lang}/{namespace}.json
    },
    react: {
      useSuspense: false, 
    },
  });

export default i18n;

