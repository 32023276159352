import React from 'react';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import PostAddIcon from '@mui/icons-material/PostAdd';
import UserIcon from '@mui/icons-material/Person';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import the hook
import { useAuth } from '../../contexts/AuthContext/AuthContext';
import LoginDialog from './LoginDialog';

const MyBottomNavigation: React.FC = () => {
  const [value, setValue] = React.useState<number>(0);
  const [showLoginDialog, setShowLoginDialog] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation(); // Using the hook

  const isMobile = () => window.matchMedia('(max-width: 768px)').matches;

  if (!isMobile() || location.pathname.startsWith('/details/')) {
    return null;
  }

  const handleNavigationChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/');
        break;
      case 1:
        navigate('/post');
        break;
      case 2:
        if (isAuthenticated) {
          navigate('/profile');
        } else {
          setShowLoginDialog(true);
          navigate('/');
        }
        break;
      default:
        break;
    }
  };

  return (
    <>
      <BottomNavigation
        value={value}
        onChange={handleNavigationChange}
        showLabels
        sx={{
          width: '100%',
          maxWidth: 720,
          position: 'fixed',
          bottom: 0,
          backgroundColor: '#f7f8fa',
          boxShadow: '0px -2px 10px rgba(0, 0, 0, 0.1)',
          zIndex: 1300
        }}
      >
        <BottomNavigationAction
          component={Link}
          to="/"
          label={t('home')} // Translation
          icon={<HomeIcon style={{ fontSize: '1.5rem' }} />}
        />
        <BottomNavigationAction
          component={Link}
          to="/post"
          label={t('postAdd')} // Translation
          icon={<PostAddIcon style={{ fontSize: '1.5rem' }} />}
        />
        <BottomNavigationAction
          component={Link}
          to="/profile"
          label={t('profile')} // Translation
          icon={<UserIcon style={{ fontSize: '1.5rem' }} />}
        />
      </BottomNavigation>
      <LoginDialog open={showLoginDialog} onClose={() => setShowLoginDialog(false)} />
    </>
  );
}

export default MyBottomNavigation;
