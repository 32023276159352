import React, { useEffect, useState,memo } from 'react';
import ImageCard from '../components/common/ImageCard';
import ImageCardSkeleton from '../components/common/ImageCardSkeleton';
import { Grid, Button } from '@mui/material';
import PropertyFilter from '../components/common/PropertyFilter';
import config from '../config';
interface Property {
  id: number;
  user_id: number;
  title: string;
  description: string;
  price: number;
  currency: string;
  location: string;
  type: string;
  status: string;
  area: number;
  bedrooms: number;
  bathrooms: number;
  garage: boolean;
  created_at: string;
  updated_at: string;
  address: string;
  images: string[];
  active: boolean;
  subscriptions: Subscription[];
}

interface Subscription {
  id: number;
  name: string;
  description: string;
  options: SubscriptionOption[];
}

interface SubscriptionOption {
  id: number;
  subscription_id: number;
  duration_in_weeks: number;
  cost: number;
}

const ImageCardList = () => {
  const [data, setData] = useState<Property[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [filterType, setFilterType] = useState('House');
  const [location, setLocation] = useState('Bosaso');
  const [sorting, setSorting] = useState<string>('newest');
  const limit = 4;

  const fetchProperties = async () => {
    setLoading(true);
    try {
      let endpoint = `${config.API_DOMAIN}/properties?page=${page}&limit=${limit}`;
      endpoint += `&type=${filterType}`;
      endpoint += `&location=${location}`;
      endpoint += `&sorting=${sorting}`;
      const response = await fetch(endpoint);
      const fetchedData: Property[] = await response.json();

      if (fetchedData.length === 0) {
        setHasMore(false);
      } else {
        // If the filter settings changed or it's a new page, append the new data to the existing data
        setData((prevData) => [...prevData, ...fetchedData]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setData([]);
    setPage(1);
    setHasMore(true);
    fetchProperties();
  }, [filterType, location, sorting]);

  const handleLoadMore = () => {
    setPage((prevPage) => prevPage + 1);
    fetchProperties();
  };

  return (
    <div className="property-list-container my-1">
      <PropertyFilter
        filterType={filterType}
        location={location}
        onFilterTypeChange={setFilterType}
        onLocationChange={setLocation}
        sorting={sorting}
        onSortingChange={setSorting}
      />

      <Grid container spacing={4} className="p-4 md:p-0 " style={{ marginTop: '0rem' }}>
        {data
          .sort((a, b) => {
            if (sorting === 'newest') {
              return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
            } else if (sorting === 'lowToHigh') {
              return a.price - b.price;
            } else if (sorting === 'highToLow') {
              return b.price - a.price;
            }
            return 0;
          })
          .map((property) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={property.id}>
              <ImageCard property={property} />
            </Grid>
          ))}

        {loading && Array.from(new Array(8)).map((_, index) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
            <ImageCardSkeleton />
          </Grid>
        ))}
      </Grid>

      <div className="text-center myb-8">
        {hasMore && (
          <Button variant="outlined" color="primary" onClick={handleLoadMore}>
            Load More
          </Button>
        )}
        {!hasMore && (
          <div className="text-gray-500">No more properties to show</div>
        )}
      </div>
    </div>
  );
};

export default ImageCardList;