import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { styled } from '@mui/system';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../../contexts/AuthContext/AuthContext';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router
import { notification } from 'antd';
import config from '../../config';
const Container = styled('div')({
  padding: '20px',
  borderRadius: '10px',
  background: '#ffffff',
});

const Title = styled(Typography)({
  marginBottom: '20px',
});

const LoginForm: React.FC<{ switchForm: () => void }> = ({ switchForm }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate(); // Initialize useNavigate

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${config.API_DOMAIN}/login`, {
        email,
        password,
      });
      toast.success('Login successful!');
      const token = response.data.token;
      login(token);

      // Use navigate to go to the root route
      navigate('/');
    } catch (error: any) {
      if (error.response) {
        if (error.response.status === 401) {
          toast.error('Invalid email or password');
        } else {
          const errorMessage = error.response.data.error || 'An error occurred while logging in';
          toast.error(errorMessage);
        }
      } else if (error.request) {
        toast.error('No response received from the server');
      } else {
        toast.error('An error occurred while logging in');
      }
    }
  };

  return (
    <Container>
      <ToastContainer />
      <Title variant="h4">Log In</Title>
      <Button
        variant="outlined"
        fullWidth
        startIcon={<GoogleIcon />}
        sx={{ mb: 2 }}
      >
        Continue with Google
      </Button>
      <div className="my-4 text-center">or</div>
      <TextField
        fullWidth
        variant="outlined"
        label="Email"
        type="email"
        sx={{ mb: 2 }}
        value={email}
        onChange={handleEmailChange}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Password"
        type="password"
        sx={{ mb: 4 }}
        value={password}
        onChange={handlePasswordChange}
      />
      <Button variant="contained" fullWidth color="primary" onClick={handleLogin}>
        Log in
      </Button>
      <div className="text-center my-2">Use single sign-on</div>
      <div className="text-center">
        <Link href="#" color="secondary">
          Reset password
        </Link>
      </div>
      <div className="text-center">
        No account?{' '}
        <Link href="#" color="primary" onClick={switchForm}>
          Create one
        </Link>
      </div>
    </Container>
  );
};

export default LoginForm;
