import React, { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import MyPosts from '../components/common/Myposts';
import config from '../config';
import {
  Typography,
  Card,
  List,
  Input,
  Button,
  message,
  Popconfirm,
  Spin,
  Form,
  Row,
  Col,
} from 'antd';
import {
  EditOutlined,
  UserOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';  // Import the hook

const { Title } = Typography;

interface DecodedToken {
  user_id: number;
  username: string;
  email: string;
  location: string;
  phoneNumber: string;
}

interface UserData {
  id: number;
  username: string;
  email: string;
  phone_number: string;
  created_at: string;
  last_login: string;
  role: string;
  verified: boolean;
  password: string;
}

function Profile() {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<DecodedToken | null>(null);
  const [userDetails, setUserDetails] = useState<UserData | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [profileForm] = Form.useForm();
  const [userPosts, setUserPosts] = useState<string[]>([]);
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation();  // Using the hook

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedData: DecodedToken = jwt_decode(token) as DecodedToken;
      setUserData(decodedData);

      axios
        .get(`${config.API_DOMAIN}/users/${decodedData.user_id}`)
        .then((response) => {
          setUserDetails(response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });

      axios
        .get(`${config.API_DOMAIN}/users/${decodedData.user_id}/posts`)
        .then((response) => {
          setUserPosts(response.data.posts);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, []);

  const handleUpdateProfile = () => {
    profileForm.validateFields().then((values) => {
        setIsSaving(true);

        const token = localStorage.getItem('token');
        if (!token) {
            message.error('Token is missing, please login again.');
            setIsSaving(false);
            return;
        }

        axios.put(`${config.API_DOMAIN}/users/${userData?.user_id}`, values, {
            headers: {
                'Authorization': `${token}`
            }
        })
        .then(response => {
            message.success('Profile updated successfully.');
            setIsEditing(false);
            setUserDetails({ ...userDetails, ...values });
        })
        .catch(error => {
            message.error('Failed to update profile. Please try again.');
            console.error("Error updating profile:", error);
        })
        .finally(() => {
            setIsSaving(false);
        });
    });
};


  const handleDeleteAccount = () => {
    setIsDeleting(true);
    setTimeout(() => {
      message.success('Account deleted successfully');
      setIsDeleting(false);
    }, 2000);
  };

  if (loading) return <Spin size="large" />;

  return (
    <div style={{ padding: 16 }}>
      <Row gutter={24}>
        <Col xs={24} sm={24} md={12} lg={12}>
          <Card title={t("Personal Information")} hoverable>
            {isEditing ? (
              <Form form={profileForm} onFinish={handleUpdateProfile} layout="vertical">
                <Form.Item
                  name="username"
                  label={t("Name")}
                  initialValue={userDetails?.username}
                  rules={[{ required: true, message: t('Please enter your name') }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={t("Email")}
                  initialValue={userDetails?.email}
                  rules={[{ required: true, message: t('Please enter your email'), type: 'email' }]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  name="phone_number"
                  label={t("Phone Number")}
                  initialValue={userDetails?.phone_number}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <Button type="default" htmlType="submit" loading={isSaving}>
                    {t('Save Changes')}
                  </Button>
                  <Button type="default" onClick={() => setIsEditing(false)} style={{ marginLeft: 8 }}>
                    {t('Cancel')}
                  </Button>
                </Form.Item>
              </Form>
            ) : (
              <List
                itemLayout="horizontal"
                dataSource={[
                  {
                    title: t('Name'),
                    icon: <UserOutlined />,
                    content: userDetails?.username || t('Not provided'),
                  },
                  {
                    title: t('Email'),
                    icon: <UserOutlined />,
                    content: userDetails?.email || t('Not provided'),
                  },
                  {
                    title: t('Phone Number'),
                    icon: <UserOutlined />,
                    content: userDetails?.phone_number || t('Not provided'),
                  },
                ]}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={item.icon}
                      title={item.title}
                      description={item.content}
                    />
                  </List.Item>
                )}
              />
            )}
            <div style={{ textAlign: 'center', marginTop: 16 }}>
              {!isEditing ? (
                <Button
                  type="default"
                  onClick={() => setIsEditing(true)}
                  icon={<EditOutlined />}
                  style={{ width: 120 }}
                >
                  {t('Edit Profile')}
                </Button>
              ) : null}
              
              <Button
                type="default"
                onClick={handleLogout}
                style={{ width: 120, marginLeft: 8 }}
              >
                {t('Logout')}
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
);

}

export default Profile;
