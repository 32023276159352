import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Import the useTranslation hook
import HomeIcon from '@mui/icons-material/Home';
import CarIcon from '@mui/icons-material/DriveEta';
import TshirtIcon from '@mui/icons-material/Style';
import PhoneIcon from '@mui/icons-material/Phone';
import SofaIcon from '@mui/icons-material/Weekend';
import WorkIcon from '@mui/icons-material/Work';
import ServiceIcon from '@mui/icons-material/RoomService';
import PetIcon from '@mui/icons-material/Pets';
import MachineryIcon from '@mui/icons-material/Build';
import PartsIcon from '@mui/icons-material/Extension';

function Categories() {
  const { t } = useTranslation(); // Initialize the useTranslation hook

  return (
    <div className="overflow-x-auto py-3 whitespace-nowrap">
      <div className="flex justify-start items-center space-x-3">
        <Link to="/properties">
          <div 
            className="flex flex-col items-center p-3 border-b-2 border-transparent hover:text-black hover:border-black text-gray-600"
          >
            <HomeIcon fontSize="small" />
            <span className="text-sm mt-1">{t('categories.realEstate')}</span>
          </div>
        </Link>

        <Link to="/soon">
          <div 
            className="flex flex-col items-center p-3 border-b-2 border-transparent hover:text-black hover:border-black text-gray-600"
          >
            <CarIcon fontSize="small" />
            <span className="text-sm mt-1">{t('categories.vehicles')}</span>
          </div>
        </Link>

       

        <Link to="/soon">
          <div 
            className="flex flex-col items-center p-3 border-b-2 border-transparent hover:text-black hover:border-black text-gray-600"
          >
            <PhoneIcon fontSize="small" />
            <span className="text-sm mt-1">{t('categories.electronics')}</span>
          </div>
        </Link>

        <Link to="/soon">
          <div 
            className="flex flex-col items-center p-3 border-b-2 border-transparent hover:text-black hover:border-black text-gray-600"
          >
            <SofaIcon fontSize="small" />
            <span className="text-sm mt-1">{t('categories.homeFurniture')}</span>
          </div>
        </Link>

        <Link to="/soon">
          <div 
            className="flex flex-col items-center p-3 border-b-2 border-transparent hover:text-black hover:border-black text-gray-600"
          >
            <WorkIcon fontSize="small" />
            <span className="text-sm mt-1">{t('categories.jobPostings')}</span>
          </div>
        </Link>

       

       

       
        <Link to="/soon">
          <div 
            className="flex flex-col items-center p-3 border-b-2 border-transparent hover:text-black hover:border-black text-gray-600"
          >
            <PartsIcon fontSize="small" />
            <span className="text-sm mt-1">{t('categories.spareParts')}</span>
          </div>
        </Link>

      </div>
    </div>
  );
}

export default Categories;
