import React, { FC, useState, useRef } from 'react';
import {
  IconButton,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Tooltip,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuIcon from '@mui/icons-material/Menu';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { useAuth } from '../../contexts/AuthContext/AuthContext';
import LoginDialog from './LoginDialog';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddAlertRoundedIcon from '@mui/icons-material/AddAlertRounded';
import jwt_decode from 'jwt-decode';

// ... (rest of the imports)

interface DecodedToken {
  user_id: number;
  // ... (other fields if needed)
}
interface MenuItemType {
  label: string;
  onClick: () => void;
}

interface UserDropdownProps {
  menuItems: MenuItemType[];
}

const UserDropdown: FC<UserDropdownProps> = ({ menuItems }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();

  const isMobile = () => window.matchMedia('(max-width: 768px)').matches;
  if (isMobile()) {
    const token = localStorage.getItem('token');
    let userId: number | undefined;
    if (token) {
      const decodedData: DecodedToken = jwt_decode(token) as DecodedToken;
      userId = decodedData.user_id;
    }

    const mobileAction = () => {
      if (userId) {
        navigate(`/${userId}/properties`);
      } else {
        setOpenDialog(true);
      }
    };

    return (
      <>
        <Tooltip title={t('myadvertisement')}>
          <IconButton
            color="inherit"
            onClick={mobileAction}
          >
            <AddAlertRoundedIcon />
          </IconButton>
        </Tooltip>
        <LoginDialog open={openDialog} onClose={() => setOpenDialog(false)} />
      </>
    );
  }
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleMenuItemClick = (item: MenuItemType) => {
    setOpen(false);
    if (item.label === t('Ka bax')) {
      logout();
    } else if (item.label === t('login')) {
      setOpenDialog(true);
    } else if (item.label === t('profile')) {
      navigate('profile');
    } else if (item.label === t('myadvertisement')) {
      const token = localStorage.getItem('token');
      let userId: number | undefined;
      if (token) {
        const decodedData: DecodedToken = jwt_decode(token) as DecodedToken;
        userId = decodedData.user_id;
      }
      navigate(`/${userId}/properties`);
    } else {
      item.onClick();
    }
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <IconButton
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="inherit"
      >
        <MenuIcon />
        <AccountCircleIcon />
      </IconButton>
      <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {menuItems.map((item, index) => (
                    <MenuItem key={index} onClick={() => handleMenuItemClick(item)}>
                      {t(item.label)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <LoginDialog open={openDialog} onClose={() => setOpenDialog(false)} />
    </div>
  );
};

export default UserDropdown;