import React, { useState, useCallback } from 'react';
import { Typography, Checkbox, Row, Col, Form, Input, Select } from 'antd';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ImageUploader from './ImageUploader';

export interface IPropertyForm {
    title: string;
    location: string;
    description: string;
    address: string;
    price: number;
    currency: string;
    type: string;
    status: string;
    area: number;
    bedrooms: number;
    bathrooms: number;
    garage: boolean;
}

const defaultValues: IPropertyForm = {
    title: "",
    location: "",
    description: "",
    address: "",
    price: 0,
    currency: "",
    type: "apartment",
    status: "rent",
    area: 0,
    bedrooms: 0,
    bathrooms: 0,
    garage: false
};

interface SimplifiedPostPropertyProps {
    onPropertyDataChange: (data: IPropertyForm) => void;
    onImagesUpload: (images: File[]) => void;
}

const SimplifiedPostProperty: React.FC<SimplifiedPostPropertyProps> = ({ onPropertyDataChange, onImagesUpload }) => {
    const [propertyFormData, setPropertyFormData] = useState<IPropertyForm>(defaultValues);
    const { t } = useTranslation();
    const [uploadedImages, setUploadedImages] = useState<File[]>([]);

    const handleInputChange = (field: keyof IPropertyForm, value: any) => {
        const updatedData = { ...propertyFormData, [field]: value };
        setPropertyFormData(updatedData);
        onPropertyDataChange(updatedData);
    };

    return (
        <div className="mt-10">
            <div className="bg-white p-6 shadow-md rounded-md space-y-6">
                <Typography.Title level={4} className="text-center text-gray-700">
                    {t('listYourProperty')}
                </Typography.Title>

                <Form layout="vertical">
                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Form.Item label={t('title')} name="title">
                                <Input value={propertyFormData.title} onChange={(e) => handleInputChange('title', e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                        <Form.Item label={t('location')} name="location">
                                <Select value={propertyFormData.location} onChange={(value) => handleInputChange('location', value)}>
                                    <Select.Option value="Qandala">Qandala</Select.Option>
                                    <Select.Option value="Carmo">Carmo</Select.Option>
                                    <Select.Option value="Bosaso">Bosaso</Select.Option>
                                    <Select.Option value="Kismaayo">Kismaayo</Select.Option>
                                    <Select.Option value="Garowe">Garowe</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label={t('description')} name="description">
                                <Input.TextArea value={propertyFormData.description} onChange={(e) => handleInputChange('description', e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item label={t('address')} name="address">
                                <Input value={propertyFormData.address} onChange={(e) => handleInputChange('address', e.target.value)} />
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item label={t('price')} name="price">
                                <Input type="number" value={propertyFormData.price} onChange={(e) => handleInputChange('price', parseFloat(e.target.value))} />
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item label={t('currency')} name="currency">
                                <Select value={propertyFormData.currency} onChange={(value) => handleInputChange('currency', value)}>
                                    <Select.Option value="USD">{t('usd')}</Select.Option>
                                    <Select.Option value="SOS">{t('somaliShilling')}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item label={t('type')} name="type">
                                <Select value={propertyFormData.type} onChange={(value) => handleInputChange('type', value)}>
                                    <Select.Option value="apartment">{t('typeApartment')}</Select.Option>
                                    <Select.Option value="house">{t('typeHouse')}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item label={t('status')} name="status">
                                <Select value={propertyFormData.status} onChange={(value) => handleInputChange('status', value)}>
                                    <Select.Option value="rent">{t('statusRent')}</Select.Option>
                                    <Select.Option value="sell">{t('statusSell')}</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={8}>
                            <Form.Item label={t('area')} name="area">
                                <Input type="number" value={propertyFormData.area} onChange={(e) => handleInputChange('area', parseFloat(e.target.value))} />
                            </Form.Item>
                        </Col>
                        <Col xs={8}>
                            <Form.Item label={t('bedrooms')} name="bedrooms">
                                <Input type="number" value={propertyFormData.bedrooms} onChange={(e) => handleInputChange('bedrooms', parseInt(e.target.value))} />
                            </Form.Item>
                        </Col>
                        <Col xs={8}>
                            <Form.Item label={t('bathrooms')} name="bathrooms">
                                <Input type="number" value={propertyFormData.bathrooms} onChange={(e) => handleInputChange('bathrooms', parseInt(e.target.value))} />
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <Form.Item name="garage" valuePropName="checked">
                                <Checkbox checked={propertyFormData.garage} onChange={(e) => handleInputChange('garage', e.target.checked)}>
                                    {t('hasGarage')}
                                </Checkbox>
                            </Form.Item>
                        </Col>
                        <Col xs={24}>
                            <ImageUploader onFileChange={(files) => { 
                                setUploadedImages(files); 
                                onImagesUpload(files); 
                            }} />
                        </Col>
                    </Row>
                </Form>
            </div>
        </div>
    );
};

export default SimplifiedPostProperty;
