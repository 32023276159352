import React from 'react';

import { InputBase, Paper } from '@mui/material';
import { styled } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

const SearchBarContainer = styled(Paper)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '50px',
  padding: '5px 10px',
  boxShadow: 'none',
  border: '1px solid #ddd',
});

const SearchInput = styled(InputBase)({
  flex: 1,
  padding: '0px',
  fontSize: '16px',
});

interface SearchBarProps {
  className?: string;
}

function SearchBar({ className }: SearchBarProps) {
  const { t } = useTranslation();

  return (
    <SearchBarContainer className={className}>
      <SearchInput placeholder={t("search")} />
      <SearchIcon color="action" />
    </SearchBarContainer>
  );
}

export default SearchBar;
