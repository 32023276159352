import React from 'react';
import { Button, Card, Typography, CardActions, Box, styled } from '@mui/material';
import { WhatsApp } from '@mui/icons-material';

interface ContactCardProps {
  name: string;
  phoneNumber: string;
}

const StyledCard = styled(Card)(({ theme }) => ({
  width: 250,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  borderRadius: '15px',
  backgroundColor: 'white',
  '&:hover': {
    boxShadow: theme.shadows[8]
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '8px',
  flex: 1,
  width: '100%',
  margin: theme.spacing(1, 0)
}));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  width: '100%',
  justifyContent: 'center'
}));

const ContactCard: React.FC<ContactCardProps> = ({ name, phoneNumber }) => {
  return (
    <StyledCard>
      <Typography variant="h6" align="center" gutterBottom>
        {name}
      </Typography>

      <Typography variant="body2" align="center" gutterBottom>
        {phoneNumber}
      </Typography>

      <CardActions>
        <ButtonWrapper>
          <StyledButton
            variant="contained"
            style={{ backgroundColor: "#25D366", color: "white" }}
            startIcon={<WhatsApp />}
            onClick={() => window.open(`https://wa.me/${phoneNumber}`, "_blank")}
          >
            WhatsApp
          </StyledButton>

          <StyledButton
            variant="contained"
            style={{ backgroundColor: "#FF5A5F", color: "white" }}
            onClick={() => window.open(`sms:${phoneNumber}`, "_blank")}
          >
            Message
          </StyledButton>
        </ButtonWrapper>
      </CardActions>
    </StyledCard>
  );
};

export default ContactCard;
