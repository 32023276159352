import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Stepper, Step, StepLabel, Button, Container, CircularProgress, Box } from '@mui/material';
import 'tailwindcss/tailwind.css';
import Category from '../components/common/categorie';
import SimplifiedPostProperty, { IPropertyForm } from '../components/common/PostForm';
import Subscriptions, { Subscription, SubscriptionOption } from '../components/common/Subscribtions';
import Checkout from '../components/common/Checkout';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useAuth } from '../contexts/AuthContext/AuthContext';
import PropertyDetails from './ProrpertyDetails';
import { IconButton } from '@mui/material';
import config from '../config';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useTranslation } from 'react-i18next';
interface UserSubscription {
    UserID: number;
    PropertyID: number;
    SubscriptionOptionID: number;
    StartDate: string;
    EndDate: string;
    PurchaseDate: string;
}

interface DecodedToken {
    user_id: number;
}

const Posting: React.FC = () => {
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [activeStep, setActiveStep] = useState(0);
    const [loading, setLoading] = useState(false);
    const [homeType, setHomeType] = useState<string | null>(null);
    const [buyRent, setBuyRent] = useState<string | null>(null);
    const [propertyDetails, setPropertyDetails] = useState<IPropertyForm | null>(null);
    const [uploadedImages, setUploadedImages] = useState<File[]>([]);
    const [selectedSubscriptions, setSelectedSubscriptions] = useState<{ sub: Subscription; option: SubscriptionOption; }[]>([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const [paymentImages, setPaymentImages] = useState<File[]>([]);
    const { t } = useTranslation();
    const steps = [
        t('Choose Category'),
        t('Post Property'),
        t('Choose Subscription'),
        t('Checkout')
    ];

    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();

    const determineActiveStatus = (): boolean => {
        
        return false;
    };
    
    const isDataComplete = (): boolean => {
        switch (activeStep) {
            case 0:
                return selectedCategory !== null;
            case 1:
                return !!propertyDetails && 
                   uploadedImages.length > 0 && 
                   
                   propertyDetails.location !== "" &&
                   propertyDetails.description !== "" &&
                   propertyDetails.address !== "" &&
                   propertyDetails.price > 0 &&
                   propertyDetails.currency !== "" &&
                   propertyDetails.area > 0 &&
                   propertyDetails.bedrooms > 0 &&
                  

                   propertyDetails.bathrooms > 0;
                   
            case 2:
                return selectedSubscriptions.length >= 0;
            case 3:
                // You can add additional checks here if needed, 
                // or simply return true if you trust the previous steps' validations.
                return true;
            default:
                return false;
        }
    };
    

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const sendInfoToTelegram = async () => {
        const botToken = '6015394438:AAEYU8fXKycZIN6gz2BVwDQuMfy0P-Nh_vo';
        const chatId = '@test7379';
        const token = localStorage.getItem('token');
        
        if (!token) {
            console.error("Token not found in local storage");
            return;
        }
        
        let decodedData;
        try {
            decodedData = jwt_decode(token) as DecodedToken;
        } catch (err) {
            console.error("Error decoding the token:", err);
            return;
        }
        
        const userId = decodedData.user_id;
    
        const totalCost = selectedSubscriptions.reduce(
            (accumulator, current) => accumulator + current.option.cost,
            0
        );
    
        let message = `User ID: ${userId}\n`;
        message += `Total Cost: $${totalCost.toFixed(2)}`;
    
        try {
            const response = await fetch(`https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(message)}`);
            const data = await response.json();
            if (!response.ok || data.ok !== true) {
                throw new Error(data.description);
            }
        } catch (err) {
            console.error("Failed to send message to Telegram:", err);
        }
    
        for (const image of paymentImages) {
            const formData = new FormData();
            formData.append('chat_id', chatId);
            formData.append('photo', image);
    
            try {
                const response = await fetch(`https://api.telegram.org/bot${botToken}/sendPhoto`, {
                    method: 'POST',
                    body: formData
                });
                const data = await response.json();
                if (!response.ok || data.ok !== true) {
                    throw new Error(data.description);
                }
            } catch (err) {
                console.error("Failed to send photo to Telegram:", err);
            }
        }
    };
    
    const insertSubscription = async (propertyId: number) => {
        const token = localStorage.getItem('token');
        let decodedData: DecodedToken | undefined;
        if (token) {
            decodedData = jwt_decode(token) as DecodedToken;
        }
    
        if (!decodedData) {
            console.error('Error decoding token.');
            return;
                }
            
            // Sample data
            for (const selected of selectedSubscriptions) {
                const weeksToAdd = selected.option.duration_in_weeks; // Adjust this as per your data structure
                const startDate = new Date().toISOString().split('T')[0];
                const endDate = new Date(
                  new Date(startDate).getTime() + weeksToAdd * 7 * 24 * 60 * 60 * 1000
                ).toISOString().split('T')[0];
            
                const userData = {
                  user_id: decodedData.user_id,
                  property_id: propertyId,
                  subscription_option_id: selected.option.id,
                  start_date: startDate, // Set the start date to the current date
                  end_date: endDate,     // Set the end date calculated above
                  purchase_date: new Date().toISOString().split('T')[0]
                };
            
                // Send the POST request
                await fetch('https://backend-msvr.onrender.com/createsubscriptions', {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(userData)
                })
                .then(response => response.json())
                .then(data => {
                  console.log('Successfully created subscription:', data);
                })
                .catch(error => {
                  console.error('Error creating subscription:', error);
                });
              }

            };
            const createProperty = async (): Promise<number | null> => {
                if (!isAuthenticated) {
                    console.error('User is not authenticated');
                    return null;  // Ensure a return value for all code paths
                }
            
                const token = localStorage.getItem('token');
                let decodedData: DecodedToken | undefined;
                if (token) {
                    decodedData = jwt_decode(token) as DecodedToken;
                }
            
                const formData = new FormData();
                if (decodedData) {
                    formData.append('user_id', decodedData.user_id.toString());
                }
            
        // Append other form data fields using the propertyDetails state
        formData.append('title', propertyDetails?.title || '');
        formData.append('description', propertyDetails?.description || '');
        formData.append('price', propertyDetails?.price.toString() || '0');
        formData.append('currency', propertyDetails?.currency || '');
        formData.append('location', propertyDetails?.location || '');
        formData.append('address', propertyDetails?.address || '');
        formData.append('type', propertyDetails?.type || '');
        formData.append('status', propertyDetails?.status || '');
        formData.append('area', propertyDetails?.area.toString() || '0');
        formData.append('bedrooms', propertyDetails?.bedrooms.toString() || '0');
        formData.append('bathrooms', propertyDetails?.bathrooms.toString() || '0');
        formData.append('garage', propertyDetails?.garage.toString() || 'false');
        formData.append('created_at', new Date().toISOString());
        formData.append('updated_at', new Date().toISOString());
        formData.append('active', determineActiveStatus().toString()); // Add this line before appending other fields
        // Append uploaded files using the uploadedImages state
        uploadedImages.forEach((image, index) => {
            formData.append(`images[${index}]`, image);
        });
    
        try {
            const response = await axios.post(`${config.API_DOMAIN}/properties`, formData);
            if (response.status === 201 && response.data.id) {
                console.log('Property successfully created');
                return response.data.id;
            }
        } catch (error) {
            console.error('Error posting property:', error);
        }
        return null;  // Ensure it returns null if no ID was obtained
    };
    

    const handleNext = async () => {
        console.log('handleNext called');
        
        if (activeStep === steps.length - 1 && selectedSubscriptions.length > 0 && paymentImages.length === 0) {
            // If we are at the checkout step, the user has chosen subscriptions, but hasn't uploaded payment images
            toast.error("Please upload at least one payment image.");
            return;
        }
    
        if (!isDataComplete()) {
            toast.error("Please fill out all required fields and upload at least one image.");
            return;
        }
    
        setLoading(true);
        await new Promise(res => setTimeout(res, 1000)); // simulate a delay
    
        if (activeStep === steps.length - 1) {
            const propertyId = await createProperty();
            if (propertyId && selectedSubscriptions.length > 0) {
                await insertSubscription(propertyId);
                await sendInfoToTelegram();
                toast.success("Property posted successfully!");
                navigate('/'); // Navigate to home page
            } else {
                setActiveStep(0); // reset to the first step after submitting
            }
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    
        setLoading(false);
    };
    
    console.log(propertyDetails);
    console.log(uploadedImages);

    return (
        <Container maxWidth="md" className="mb-12">
            <Box display="flex" alignItems="center" mb={2}>
                <IconButton onClick={() => navigate(-1)} edge="start" aria-label={t('go back')}>
                    <ArrowBack />
                </IconButton>
            </Box>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
    
            {loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mt={7}
                >
                    <CircularProgress size={200} />
                </Box>
            ) : (
                <div className="mt-6">
                    {activeStep === 0 && (
                        <Category
                        onCategorySelect={setSelectedCategory}
                      />
                    )}
                    {activeStep === 1 && (
                        <SimplifiedPostProperty 
                        onPropertyDataChange={setPropertyDetails} 
                        onImagesUpload={setUploadedImages} 
                       />
                    )}
                    {activeStep === 2 && (
                        <Subscriptions
                            onSubscriptionChange={(selectedSubs) => setSelectedSubscriptions(selectedSubs)}
                        />
                    )}
                    {activeStep === 3 && (
                        <Checkout
                            homeType={homeType}
                            buyRent={buyRent}
                            propertyDetails={propertyDetails}
                            uploadedImages={uploadedImages}
                            selectedSubscriptions={selectedSubscriptions}
                            paymentImages={paymentImages}
                            setPaymentImages={setPaymentImages}
                        />
                    )}
                </div>
            )}
    
            <Box display="flex" justifyContent="center" alignItems="center" mt={4} gap={2}>
                <Button disabled={activeStep === 0} onClick={handleBack}>
                    {t('Back')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                >
                    {activeStep === steps.length - 1 ? t('Submit') : t('Next')}
                </Button>
            </Box>
        </Container>
    );
    
};

export default Posting;
