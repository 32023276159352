import React, { useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Link, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { styled } from '@mui/system';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config';
const Container = styled('div')({
  padding: '20px',
  borderRadius: '10px',
  background: '#ffffff',
});

const Title = styled(Typography)({
  marginBottom: '20px',
});

const RegisterForm: React.FC<{ switchForm: () => void }> = ({ switchForm }) => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [error, setError] = useState({
    fullName: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    let valid = true;
    const newError = {
      fullName: '',
      email: '',
      password: '',
      confirmPassword: '',
    };

    // ... existing validation ...

    if (valid) {
      try {
        const response = await axios.post(`${config.API_DOMAIN}/register`, {
          username: formData.fullName,
          email: formData.email,
          password: formData.password,
      });
      
        if (response.status === 201) {
          toast.success("User registered successfully");
        } else {
          toast.error("Error registering user.");
        }
      } catch (err) {
        toast.error(`Error: `);
      }
    }
  };

  return (
    <Container>
      <Title variant="h4">Create Account</Title>
      <Button
        variant="outlined"
        fullWidth
        startIcon={<GoogleIcon />}
        sx={{ mb: 2 }}
      >
        Continue with Google
      </Button>
      <div className="my-4 text-center">or</div>
      <TextField
        fullWidth
        variant="outlined"
        label="Full Name"
        type="text"
        name="fullName"
        value={formData.fullName}
        onChange={handleChange}
        error={Boolean(error.fullName)}
        helperText={error.fullName}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Email"
        type="email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        error={Boolean(error.email)}
        helperText={error.email}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Password"
        type="password"
        name="password"
        value={formData.password}
        onChange={handleChange}
        error={Boolean(error.password)}
        helperText={error.password}
        sx={{ mb: 2 }}
      />
      <TextField
        fullWidth
        variant="outlined"
        label="Confirm Password"
        type="password"
        name="confirmPassword"
        value={formData.confirmPassword}
        onChange={handleChange}
        error={Boolean(error.confirmPassword)}
        helperText={error.confirmPassword}
        sx={{ mb: 4 }}
      />
      <Button variant="contained" fullWidth color="primary" onClick={handleSubmit}>
        Create account
      </Button>
      <div className="text-center my-2">
        By clicking "Create account" or "Continue with Google", you agree to the Figma TOS and Privacy Policy.
      </div>
      <div className="text-center">
        Already have an account?{' '}
        <Link href="#" color="primary" onClick={switchForm}>
          Log in
        </Link>
      </div>
      <ToastContainer position="top-right" autoClose={5000} />
    </Container>
  );
};

export default RegisterForm;
