import React, { useEffect, useState } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { Card, Button, CardContent, Skeleton, Typography, CircularProgress, Grid, Divider, Box } from '@mui/material';
import Gallery from './Gallery';
import BedroomIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import 'tailwindcss/tailwind.css';
import { WhatsApp } from '@mui/icons-material';
import ContactCard from '../components/common/ContactCard';
import config from '../config';
import { useTranslation } from 'react-i18next';

interface PropertyData {
    id: number;
    user_id: number;
    title: string;
    description: string;
    price: number;
    currency: string;
    location: string;
    address: string;
    type: string;
    status: string;
    area: number;
    bedrooms: number;
    bathrooms: number;
    garage: boolean;
    created_at: string;
    updated_at: string;
    images: string[];
}
interface UserData {
    id: number;
    username: string;
    phone_number: string;
    // ... (other properties from the user model, if required)
}


function PropertyDetails() {
    const { id } = useParams();
    const [property, setProperty] = useState<PropertyData | null>(null);
    const [user, setUser] = useState<UserData | null>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        fetch(`${config.API_DOMAIN}/properties/${id}`)
            .then(response => response.json())
            .then(data => setProperty(data))
            .catch(error => console.error('Error fetching property data:', error));
    }, [id]);

    useEffect(() => {
        if (property) {
            fetch(`${config.API_DOMAIN}/users/${property.user_id}`)
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        setUser(data.data);
                    } else {
                        console.error('Error fetching user data:', data.error);
                    }
                })
                .catch(error => console.error('Error fetching user data:', error));
        }
    }, [property]);

    if (!property) {
        return (
            <div className="flex flex-col p-4 space-y-4">
                <Skeleton variant="rectangular" width="100%" height={200} />
                <Skeleton variant="text" width="60%" height={40} />
                <Skeleton variant="text" width="40%" />
                <Skeleton variant="text" width="50%" height={40} />
                <div className="flex space-x-4 mt-4">
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="text" width="50" />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="text" width="50" />
                    <Skeleton variant="circular" width={40} height={40} />
                    <Skeleton variant="text" width="50" />
                </div>
                <Skeleton variant="text" width="80%" />
                <Skeleton variant="text" width="60%" />
                <Skeleton variant="text" width="70%" />
                <Skeleton variant="text" width="50%" />
            </div>
        );
    }

    return (
        <Box p={[4, 6, 8]} className="bg-gray-50 min-h-screen">
            <Box className="flex items-center mb-4">
                <Button 
                    onClick={() => navigate('/')} 
                    style={{
                        minWidth: 'auto',
                        padding: '5px',
                        borderRadius: '50%',
                        transition: 'background-color 0.3s',
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = 'rgba(0, 0, 0, 0.1)'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = 'transparent'}
                >
                    <ArrowBackIosIcon style={{ color: 'rgba(0, 0, 0, 0.7)' }} />
                
                   
                </Button>
            </Box>
            
            <Gallery images={property.images} />

            <Box mt={6} className="flex flex-wrap -mx-2 md:-mx-4 mb-12">
                <Box className="w-full md:w-2/3 px-2 md:px-4">
                    <Box className="bg-white p-4 md:p-6 rounded-md shadow-md mb-6">
                        <Typography variant="h4" gutterBottom>{property.address}</Typography>
                        <Typography variant="subtitle1" color="textSecondary" className="mb-4">{property.location}</Typography>
                        <Typography variant="h5" className="mb-4">${property.price.toLocaleString()} {property.currency}</Typography>
                        <Divider className="my-4" />
                        <Box className="flex space-x-2 md:space-x-4 mt-4 flex-wrap">
                            <Box className="flex items-center mb-2 md:mb-0">
                                <BedroomIcon className="mr-1 md:mr-2 text-blue-500" />
                                <Typography variant="subtitle1">{property.bedrooms} {t('beds')}</Typography>
                            </Box>
                            <Box className="flex items-center mb-2 md:mb-0">
                                <BathtubIcon className="mr-1 md:mr-2 text-blue-500" />
                                <Typography variant="subtitle1">{property.bathrooms} {t('baths')}</Typography>
                            </Box>
                            <Box className="flex items-center">
                                <SquareFootIcon className="mr-1 md:mr-2 text-blue-500" />
                                <Typography variant="subtitle1">{property.area} sqft</Typography>
                            </Box>
                        </Box>
                        <Box className="mt-6">
                            <Typography variant="h6" gutterBottom>{t('description')}</Typography>
                            <Typography variant="body1">{property.description}</Typography>
                        </Box>
                    </Box>
                    <Box className="bg-white p-4 md:p-6 rounded-md shadow-md">
                        <Typography variant="subtitle1" gutterBottom>{t('propertyInformation')}</Typography>
                        <Typography variant="subtitle1" gutterBottom>{t('type')}: {property.type}</Typography>
                        <Typography variant="subtitle1" gutterBottom>{t('status')}: {property.status}</Typography>
                        <Typography variant="subtitle1" gutterBottom>{t('garage')}: {property.garage ? t('yes') : t('no')}</Typography>
                    </Box>
                </Box>
                <Box className="hidden md:block w-full md:w-1/3 px-2 md:px-4 mt-6 md:mt-0">
                {user && <ContactCard name={user.username} phoneNumber={user.phone_number} />}
            </Box>
            <Box className="md:hidden fixed bottom-0 left-0 w-full bg-white p-4 shadow-lg flex justify-between">
                <Button
                    variant="contained"
                    style={{ backgroundColor: "#25D366", color: "white", flex: 1, margin: '0 8px' }}
                    startIcon={<WhatsApp />}
                    onClick={() => user && window.open(`https://wa.me/${user.phone_number}`, "_blank")}
                >
                    WhatsApp
                </Button>
                <Button
                    variant="contained"
                    style={{ backgroundColor: "#FF5A5F", color: "white", flex: 1, margin: '0 8px' }}
                    onClick={() => user && window.open(`sms:${user.phone_number}`, "_blank")}
                >
                    {t('call')}
                </Button>
            </Box>
        </Box>
        </Box>
    );
}

export default PropertyDetails;
