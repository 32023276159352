import React, { useState,useEffect, FC } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF5A5F',
    },
    secondary: {
      main: '#008489',
    },
  },
  typography: {
    fontFamily: '"Airbnb Cereal App", "Roboto", "Helvetica", "Arial", sans-serif',
  },
});

interface LoginDialogProps {
  open: boolean;
  onClose: () => void;
}
const LoginDialog: FC<LoginDialogProps> = ({ open, onClose }) => {
  const [isLogin, setIsLogin] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 600);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    
    // Cleanup: Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const switchForm = () => {
    setIsLogin(!isLogin);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullScreen={isMobile}>
        <DialogContent>
          {isLogin ? (
            <LoginForm switchForm={switchForm} />
          ) : (
            <RegisterForm switchForm={switchForm} />
          )}
        </DialogContent>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ position: 'absolute', top: 10, right: 10 }}
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
    </ThemeProvider>
  );
};

export default LoginDialog;