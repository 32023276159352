import React from 'react';
import LockIcon from '@mui/icons-material/Lock';

function Security() {
  return (
    <div className="flex items-center bg-white p-1 rounded-lg  w-full max-w-xs">
      
      {/* Security Icon */}
      <div className="mr-1 ml-3">
      <LockIcon className="text-black" style={{ width: '18px', height: '15px', fontSize: '18px' }} />

      </div>

      {/* Safe Payment */}
      <span style={{ fontSize: '10px', whiteSpace: 'nowrap' }} className="border-r border-gray-300 pr-4 mr-4">Safe Payment</span>

      {/* Security Protection */}
      <span style={{ fontSize: '10px', whiteSpace: 'nowrap' }} className="border-r border-gray-300 pr-4 mr-4">Security Protection</span>

      {/* Purchase Protection */}
      <span style={{ fontSize: '10px', whiteSpace: 'nowrap' }} >Purchase Protection</span>

      {/* Media queries for larger screens (optional) */}
      
    </div>
  )
}

export default Security;
