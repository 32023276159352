import React, { useState, useEffect } from 'react';
import {
  Card, Typography, List, Space, Tag, Row, Col, Tooltip, Spin, Alert
} from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, DollarCircleOutlined } from '@ant-design/icons';
import "antd/dist/reset.css";
import config from '../../config';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
const API_URL_BASE = config.API_DOMAIN;

export type SubscriptionOption = {
  id: number;
  subscription_id: number;
  duration_in_weeks: number;
  cost: number;
};

export type Subscription = {
  id: number;
  name: string;
  description: string;
  options: SubscriptionOption[];
};

interface SubscriptionsProps {
  onSubscriptionChange: (selectedSubs: { sub: Subscription, option: SubscriptionOption }[]) => void;
}

const getEndDate = (weeks: number) => {
  return moment().add(weeks, 'weeks').format('LL'); // Formats date as "Month Day, Year"
};

const Subscriptions: React.FC<SubscriptionsProps> = ({ onSubscriptionChange }) => {
  const [selectedSubscriptions, setSelectedSubscriptions] = useState<number[]>([]);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<{ sub: Subscription, option: SubscriptionOption }[]>([]);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [currentSub, setCurrentSub] = useState<Subscription | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const { t } = useTranslation();
  useEffect(() => {
    fetch(`${API_URL_BASE}/subscriptions`)
      .then(response => response.json())
      .then(data => setSubscriptions(data))
      .catch(error => {
        console.error("Error fetching data:", error);
        setError(error);
      });
  }, []);

  const handleOptionChange = (sub: Subscription, option: SubscriptionOption) => {
    setSelectedOptions(prevOptions => {
      const existingIndex = prevOptions.findIndex(opt => opt.sub.id === sub.id);
      if (existingIndex !== -1) {
        const newOptions = [...prevOptions];
        newOptions[existingIndex] = { sub, option };
        return newOptions;
      } else {
        return [...prevOptions, { sub, option }];
      }
    });
  };

  useEffect(() => {
    onSubscriptionChange(selectedOptions);
  }, [selectedOptions, onSubscriptionChange]);

  const handleSubscriptionToggle = (sub: Subscription) => {
    setCurrentSub(sub);
    setShowOptionsModal(true);
  };

  const handleOptionSelect = (option: SubscriptionOption) => {
    handleOptionChange(currentSub!, option);
    setShowOptionsModal(false);
  };

  const handleCardClick = (sub: Subscription, option: SubscriptionOption) => {
    handleOptionChange(sub, option);
  };

  const isSelected = (subId: number, optionId: number) => {
    return selectedOptions.some(opt => opt.sub.id === subId && opt.option.id === optionId);
  };

  return (
    <div style={{ padding: '10px', fontFamily: 'Arial, sans-serif' }}>
        <Typography.Title level={3} style={{ textAlign: 'center', marginBottom: '20px', fontWeight: 500, color: '#555' }}>
            {t('Choose Your Subscription')}
        </Typography.Title>

        {subscriptions.length === 0 ? <Spin /> :
            subscriptions.map(sub => (
                <div key={sub.id} style={{ marginBottom: '25px' }}>
                    <Typography.Title level={4} style={{ color: '#333', marginBottom: '15px' }}>{t(sub.name)}</Typography.Title>
                    <Typography.Text>{t(`description_${sub.name}`)}</Typography.Text>
                    <List
                        dataSource={sub.options}
                        grid={{ gutter: 16, column: 1 }}
                        renderItem={option => (
                            <List.Item key={option.id}>
                                <Card
                                    hoverable
                                    onClick={() => handleCardClick(sub, option)}
                                    style={{
                                        width: '100%',
                                        borderRadius: '20px',
                                        boxShadow: isSelected(sub.id, option.id) ? '0px 4px 20px rgba(0, 0, 0, 0.12)' : '0px 4px 15px rgba(0, 0, 0, 0.05)',
                                        border: isSelected(sub.id, option.id) ? '2px solid #1890ff' : 'none',
                                        cursor: 'pointer',
                                        overflow: 'hidden',
                                        position: 'relative'
                                    }}
                                >
                                    <Row justify="space-between" align="middle">
                                        <Col span={8}>
                                            <Space>
                                                <ClockCircleOutlined />
                                                <Typography.Text style={{ fontSize: '16px', fontWeight: 'bold', color: '#555' }}>
                                                    {option.duration_in_weeks} {t('week(s)')}
                                                </Typography.Text>
                                            </Space>
                                        </Col>
                                        <Col span={8}>
                                            <Typography.Text style={{ fontSize: '14px', color: '#888' }}>
                                                {t('Ends on')} {getEndDate(option.duration_in_weeks)}
                                            </Typography.Text>
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'right' }}>
                                            <Space>
                                                <DollarCircleOutlined />
                                                <Typography.Text style={{ color: '#1890ff', fontSize: '18px', fontWeight: 'bold' }}>
                                                    ${option.cost}
                                                </Typography.Text>
                                            </Space>
                                        </Col>
                                    </Row>
                                    <Tooltip title={option.cost < 10 ? t("This option has a discount!") : t("This option is popular among our users!")}>
                                        <Tag color={option.cost < 10 ? "green" : "blue"} style={{ position: 'absolute', top: '10px', right: '10px' }}>
                                            {option.cost < 10 ? t("Discount") : t("Popular")}
                                        </Tag>
                                    </Tooltip>
                                </Card>
                            </List.Item>
                        )}
                    />
                </div>
            ))}
        {error && <Alert message={t('Error fetching subscriptions. Please try again later.')} type="error" />}
    </div>
);



}

export default Subscriptions;
