import React, { useState } from 'react';
import {
  IconButton,
  SelectChangeEvent,
  useMediaQuery,
  Alert,
  Box,
} from '@mui/material';
import { FaWhatsapp } from 'react-icons/fa';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../common/button';
import Language from '../common/language';
import UserDropdown from '../common/userDropdown';
import SearchBar from '../common/search';  // Assuming SearchBar is in the same directory
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../contexts/AuthContext/AuthContext';

const Header: React.FC = () => {
  const { i18n, t } = useTranslation();
  const [loggedIn, setLoggedIn] = useState(false);
  const history = useNavigate();
  const isMobile = useMediaQuery('(max-width: 600px)');
  const location = useLocation();
  const [locationValue, setLocationValue] = useState('');
  const [showPromo, setShowPromo] = useState(true); // For Promo Banner
  const { isAuthenticated, logout } = useAuth();

  const handleButtonClick = () => {
    console.log("Button clicked");
    history('/post');
  };

  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
  };

  const handlePromoClose = () => {
    setShowPromo(false);  // Close the Promo Banner
  };

  const showPostButtonOnMobile = isMobile && location.pathname === '/';
  const toggleLoggedIn = () => {
    setLoggedIn(!loggedIn);
  };

  const getUserMenuItems = () => {
    if (isAuthenticated) {
      return [
        { label: t('profile'), onClick: () => {} },
        { label: t('myadvertisement'), onClick: () => {} },
        { label: t('logout'), onClick: logout },
      ];
    } else {
      return [
        { label: t('login'), onClick: () => {} },
      ];
    }
  };

  return (
    <div className="relative">
        {/* Promo Banner */}
        {showPromo && (
            <Alert severity="info" action={
              <IconButton 
                  color="inherit" 
                  size="small" 
                  onClick={handlePromoClose}
                  sx={{ marginTop: '-8px', marginBottom: '-8px' }}
              >
                  <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
              <Box textAlign="center">
                  {t('promoMessage')} 
                  <a href="https://wa.me/905523657379" target="_blank" rel="noopener noreferrer">
                      <WhatsAppIcon fontSize="small" sx={{ verticalAlign: 'middle', ml: 1 }} />
                  </a>
              </Box>
            </Alert>
        )}

        <div className="bg-white shadow-md p-2 flex justify-between items-center"> {/* Reduced padding here */}
          <div className="logo-container" onClick={() => history('/')}>
            <img src="/logo-no-background.svg" alt="Logo" style={{ cursor: 'pointer', maxHeight: '40px' }} /> {/* Added max height for the logo */}
          </div>

          <div className="flex items-center justify-center space-x-4 flex-1 hidden md:flex">
            <SearchBar className="w-full md:w-[400px]" />
          </div>

          <div className="flex items-center space-x-4 ml-auto">
            {!isMobile && (
                <div className="hidden md:flex space-x-1">
                    <div className="mt-2">
                        <Button onClick={handleButtonClick}>
                            {t('postProperty')}
                        </Button>
                    </div>
                </div>
            )}
            <Language handleLanguageChange={handleLanguageChange} />
            <UserDropdown menuItems={getUserMenuItems()} />
          </div>
        </div>

        {showPostButtonOnMobile && (
            <div className="fixed bottom-0 left-0 w-full flex justify-center p-1 bg-white shadow-md z-50">
                {/* Your mobile button content */}
            </div>
        )}
    </div>
);

};

export default Header;
