import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { styled } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next';

// Define the Property interface here
interface Property {
  id: number;
  user_id: number;
  title: string;
  description: string;
  price: number;
  currency: string;
  location: string;
  type: string;
  status: string;
  area: number;
  bedrooms: number;
  bathrooms: number;
  garage: boolean;
  created_at: string;
  updated_at: string;
  address: string;
  images: string[];
  active: boolean;
  subscriptions: Subscription[];
}

interface Subscription {
  id: number;
  name: string;
  description: string;
  options: SubscriptionOption[];
}

interface SubscriptionOption {
  id: number;
  subscription_id: number;
  duration_in_weeks: number;
  cost: number;
}

interface ImageCardProps {
  property: Property;
}

const StyledCard = styled(Card)(() => ({
  '@media (max-width: 600px)': {
    width: '100%',
  },
  maxWidth: 1000,
  width: '95%',
  margin: '0 auto',
  borderRadius: 20,
  boxShadow: 'none',
  minHeight: 400,
}));

const ImageStyles = styled('img')(() => ({
  width: '100%',
  height: '300px',
  objectFit: 'cover',
  borderRadius: '20px',
  '@media (max-width: 600px)': {
    height: '300px',
  },
}));

const PlaceholderImage = styled('div')(() => ({
  width: '100%',
  height: '300px',
  backgroundColor: '#f0f0f0',
  borderRadius: '20px',
  '@media (max-width: 600px)': {
    height: '300px',
  },
}));

const Promo = styled('div')(() => ({
  position: 'absolute',
  top: '10px',
  right: '10px',
  backgroundColor: '#f44336',
  color: '#fff',
  padding: '5px 10px',
  borderRadius: '5px',
  fontSize: '14px',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const SaleOrRentWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px'
}));

const GreenDot = styled('div')(() => ({
  backgroundColor: '#4CAF50',
  width: '10px',
  height: '10px',
  borderRadius: '50%',
  marginRight: '5px',
}));

const DaysAgoLabel = styled('div')(() => ({
  position: 'absolute',
  top: '10px',
  left: '10px',
  backgroundColor: 'rgba(0, 0, 0, 0.6)',
  color: '#fff',
  padding: '3px 7px',
  borderRadius: '5px',
  fontSize: '12px',
}));
const ViewersCount = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  marginTop: '10px',
  color: '#4CAF50',  // Green color to indicate activeness
  '& svg': {
    marginRight: '5px'
  }
}));

function daysBetween(dateString: string): number {
  if (!dateString || typeof dateString !== 'string') {
    console.warn('Invalid dateString:', dateString);
    return 0;
  }

  const currentDate = new Date();
  const givenDate = new Date(dateString);

  const millisecondsInADay = 1000 * 60 * 60 * 24;

  const differenceInMilliseconds = currentDate.getTime() - givenDate.getTime();
  const differenceInDays = Math.floor(differenceInMilliseconds / millisecondsInADay);

  return differenceInDays;
}

function ImageCard(props: ImageCardProps) {
  const { t } = useTranslation();
  const {
    images,
    location: city,
    price,
    currency,
    address,
    bedrooms,
    id,
    subscriptions,
  } = props.property;

  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [viewersCount, setViewersCount] = useState(0);
  const navigate = useNavigate();
 
  const daysSinceCreation = daysBetween(props.property.created_at);

  useEffect(() => {
    const updateViewers = () => {
      setViewersCount((prevCount) => (prevCount >= 12 ? 0 : prevCount + 1));
    };

    const interval = setInterval(updateViewers, 5000); // Update viewers count every 3 seconds
    return () => clearInterval(interval); // Clear the interval when the component is unmounted
  }, []);

  useEffect(() => {
    const preloadImages = async () => {
      try {
        const promises = images.map((src) => {
          return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = resolve;
            img.onerror = reject;
          });
        });

        await Promise.all(promises);
        setImagesLoaded(true);
      } catch (error) {
        console.error("Error preloading images:", error);
      }
    };

    preloadImages();
  }, [images]);

  const hasFeaturedSubscription = subscriptions && subscriptions.some(
    (subscription) => subscription.name === "Featured Listings"
  );

  const handleCardClick = () => {
    console.log(id);
    try {
      navigate(`/details/${id}`);
    } catch (error) {
      console.error("Error navigating:", error);
    }
  };
  useEffect(() => {
    const updateViewers = () => {
      const randomViewers = Math.floor(Math.random() * 13);  // Random number between 0 and 12
      setViewersCount(randomViewers);
    };

    const interval = setInterval(updateViewers, 5000); // Update viewers count every 3 seconds
    return () => clearInterval(interval); // Clear the interval when the component is unmounted
  }, []);

  return (
    <Link to={`/details/${id}`} style={{ textDecoration: 'none' }}>
        <StyledCard className="transition-all duration-300 ease-in-out" onClick={handleCardClick}>
            <Carousel
                autoPlay={false}
                animation="slide"
                indicators={false}
                navButtonsAlwaysVisible={imagesLoaded}
                navButtonsProps={{ style: { visibility: imagesLoaded ? 'visible' : 'hidden' } }}
            >
                {imagesLoaded ? (
                    images.map((src, index) => (
                        <div key={index} style={{ position: 'relative' }}>
                            <ImageStyles className="fadeIn" src={src} alt={city} loading="lazy" />
                            {daysSinceCreation < 31 && (
                                <DaysAgoLabel>
                                    {t('New')} - {daysSinceCreation} {daysSinceCreation === 1 ? t('day') : t('days')} {t('ago')}
                                </DaysAgoLabel>
                            )}
                            {hasFeaturedSubscription && (
                                <Promo>
                                    <span style={{ marginRight: '5px' }}>{t('Featured')}</span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="#fff"
                                        width="16px"
                                        height="16px"
                                    >
                                        <path d="M0 0h24v24H0z" fill="none" />
                                        <path fill="white" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
                                    </svg>
                                </Promo>
                            )}
                        </div>
                    ))
                ) : (
                    <PlaceholderImage />
                )}
            </Carousel>

            <CardContent className="font-roboto p-5 relative">  {/* Added relative positioning here */}
                <ViewersCount style={{ position: 'absolute', top: '10px', right: '10px' }}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="#4CAF50"
                        width="20px"
                        height="20px"
                    >
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M12 5C6.48 5 2 8.37 2 12s4.48 7 10 7 10-2.37 10-7-4.48-7-10-7zm0 12a5 5 0 1 1 0-10 5 5 0 0 1 0 10zm0-2a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    </svg>
                    {viewersCount} {t('Viewing')}
                </ViewersCount>

                <SaleOrRentWrapper>
                    <GreenDot />
                    <Typography variant="body2" color="textSecondary">
                        {t(`propertyStatus.${props.property.status}`)}
                    </Typography>
                </SaleOrRentWrapper>

                <Box className="flex justify-between items-center">
                    <Typography variant="h4" color="textPrimary" className="font-semibold">
                        ${price} 
                    </Typography>
                    <Typography variant="subtitle1" color="textSecondary">
                        {bedrooms} {t('bedrooms')}
                    </Typography>
                </Box>

                <Typography variant="body2" color="textSecondary" align="left" className="mt-2">
                    {city}
                </Typography>

                <Typography variant="body2" color="textSecondary" align="left">
                    {address}
                </Typography>

                <Box className="h-px bg-gray-300 my-4"></Box>
            </CardContent>
        </StyledCard>
    </Link>
);


}

export default ImageCard;