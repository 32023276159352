import React, { useEffect, useState } from 'react';
import Promoted from './promoted';
import config from '../../config';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import { useTranslation } from 'react-i18next'; // Import the hook

interface Property {
    id: number;
    title: string;
    price: number;
    status: string;
    images: string[];
    // Add other fields if needed...
}

function Showpromoted() {
  const [properties, setProperties] = useState<Property[]>([]);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchProperties() {
      try {
        const response = await fetch(`${config.API_DOMAIN}/properties?page=1&limit=20`);
        const data = await response.json();
        setProperties(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching properties:', error);
        setLoading(false);
      }
    }

    fetchProperties();
  }, []);

  return (
    <div className="p-4">
      <h1 className="text-7xs font-bold mb-1">
        <FlashOnIcon style={{ verticalAlign: 'middle' }} />
        {t('promotedProperties')}
      </h1>
      <div style={{
        display: 'flex',
        overflowX: 'scroll',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
      }}>
        {loading ? (
          Array(5).fill(null).map((_, index) => (
            <div key={index} style={{ flex: '0 0 100px', marginRight: '10px' }}>
              <Promoted
                id={index}
                imageUrl=""
                price={0}
                status=""
                loading={loading}
              />
            </div>
          ))
        ) : (
          properties.map(property => (
            <div key={property.id} style={{ flex: '0 0 100px', marginRight: '10px' }}>
              <Promoted
                id={property.id}
                imageUrl={property.images[0]}
                price={property.price}
                status={property.status}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Showpromoted;
