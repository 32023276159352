import React from 'react';
import { Paper, Typography, Button, Avatar, Chip, Grid, Box } from '@mui/material';
import { FaEye, FaAd } from 'react-icons/fa';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { PostData as PostDataType, Subscription } from '../../pages/Myposts';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';  // Import the hook
// Define your custom theme
const customTheme = createTheme({
  palette: {
    primary: {
      main: '#007bff',
    },
    secondary: {
      main: '#ff5722',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: '12px',
  backgroundColor: '#f5f5f5',
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
  padding: theme.spacing(1, 2),
  borderRadius: '8px',
  fontWeight: 'bold',
}));

function formatDate(isoDateString: string): string {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };
  return new Date(isoDateString).toLocaleDateString(undefined, options);
}

function PostData({ post }: { post: PostDataType }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={customTheme}>
      <StyledPaper elevation={3}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={4}>
            <Avatar alt={t(post.title)} src={post.images[0]} variant="rounded" style={{ width: '100%', display: 'block' }} />
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h6" noWrap>
              {t(post.title)}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {t(post.address)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" noWrap>
              {t(post.description)}
            </Typography>
            <Box mt={2}>
              <Typography variant="h6" color="primary">
                {post.price} {t(post.currency)}
              </Typography>
            </Box>
            {post.subscriptions && post.subscriptions.length > 0 && (
              <Box mt={2}>
                <Typography variant="subtitle2">{t('Subscriptions')}</Typography>
                {post.subscriptions.map((subscription, index) => (
                 <Chip key={index} label={`${t(subscription.Name)} (${t('Ends')} ${formatDate(subscription.Options[0].EndDate)})`} variant="filled"  style={{ margin: '4px' }} />

                ))}
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              {!post.active ? (
                <StyledButton variant="contained" color="primary" fullWidth>
                  {t('Pending')}
                </StyledButton>
              ) : (
                <>
                  <StyledButton variant="contained" color="primary" startIcon={<FaEye />} fullWidth onClick={() => navigate(`/details/${post.id}`)}>
                    {t('View')}
                  </StyledButton>
                  
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </StyledPaper>
    </ThemeProvider>
  );

}

export default PostData;
