import React from 'react';
import ReactDOM from 'react-dom';
import { useLocation } from 'react-router-dom';

import App from './pages/App';
import { AuthProvider } from './contexts/AuthContext/AuthContext';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MantineProvider } from '@mantine/core';
import MyBottomNavigation from './components/common/BottomNavigation';


// Import the default theme from '@chakra-ui/react'
import {
  ChakraProvider,
  ColorModeScript,
  extendTheme,
  theme as defaultTheme,
} from '@chakra-ui/react';

// Extend the theme with no custom configurations (use default theme)
import './index.css';

import { PrimeReactProvider, PrimeReactContext } from 'primereact/api';
function Main() {
  const location = useLocation();

  return (
    <div className="min-h-screen bg-white">
      <ToastContainer />
      <App />
      {location.pathname !== "/post" && <MyBottomNavigation />}
    </div>
  );
}

ReactDOM.render(
  <PrimeReactProvider>
    <I18nextProvider i18n={i18n}>
      <BrowserRouter>
        <AuthProvider>
          <Main />
        </AuthProvider>
      </BrowserRouter>
    </I18nextProvider>
  </PrimeReactProvider>,
  document.getElementById('root')
);