import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import { EnvironmentOutlined } from '@ant-design/icons';

const { Option } = Select;

interface LocationInputProps {
  location: string;
  onLocationChange: (location: string) => void;
}

const majorCitiesInSomalia = [
  'Qandala',
  'Carmo',
  'Bosaso',
  'Kismaayo',
  'Garowe', // Default option
  // Add more major cities here
];

const LocationInput: React.FC<LocationInputProps> = ({
  location,
  onLocationChange,
}) => {
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Set "Garoowe" as the initial default location
    onLocationChange('Bosaso');
  }, [onLocationChange]);

  const handleLocationChange = (value: string) => {
    onLocationChange(value);
    setError(null); // Clear the error when the location is changed
  };

  return (
    <div>
      <Select
        placeholder="Select Location"
        value={location}
        onChange={handleLocationChange}
        showSearch
        optionFilterProp="children"
        allowClear
        suffixIcon={<EnvironmentOutlined />}
        style={{ minWidth: '120px', marginLeft: 'auto' }}
        dropdownStyle={{ zIndex: 9999 }} // Adjust the zIndex as needed
      >
        {majorCitiesInSomalia.map((city) => (
          <Option key={city} value={city}>
            {city}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default LocationInput;
