import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next'; // Import useTranslation hook

interface ImageUploaderProps {
  onFileChange?: (files: File[]) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({ onFileChange }) => {
  const [currentFiles, setCurrentFiles] = useState<File[]>([]);
  const { t } = useTranslation(); // Instantiate the translation function

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*' as any,
    multiple: true,
    onDrop: (acceptedFiles) => {
      setCurrentFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
  });

  const removeFile = (targetFile: File) => {
    setCurrentFiles((prevFiles) => prevFiles.filter((file) => file !== targetFile));
  };

  useEffect(() => {
    if (onFileChange) {
      onFileChange(currentFiles);
    }
  }, [currentFiles, onFileChange]);

  return (
    <div className="max-w-3xl mx-auto bg-white p-4 shadow-lg rounded-md">
      <div
        {...getRootProps()}
        className="flex flex-col items-center justify-center border-dashed border-4 border-gray-300 p-12 rounded-lg cursor-pointer hover:bg-gray-100 transition ease-in-out duration-150"
      >
        <input {...getInputProps()} />
        <Button variant="contained" color="primary" component="span" className="mt-4">
          {t('Upload Images')}
        </Button>
      </div>

      {currentFiles.length > 0 && (
        <Box mt={4} className="grid grid-cols-2 md:grid-cols-3 gap-4">
          {currentFiles.map((file: any) => (
            <Box key={file.path} position="relative">
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                className="w-full h-32 md:h-48 object-cover rounded-md shadow-md"
              />
              <IconButton
                size="small"
                onClick={() => removeFile(file)}
                className="absolute top-1 right-1 bg-white bg-opacity-70 rounded-full"
              >
                <DeleteIcon fontSize="inherit" />
              </IconButton>
            </Box>
          ))}
        </Box>
      )}
    </div>
  );
};

export default ImageUploader;
